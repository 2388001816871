import type {
  BookingSlot,
  BookingSlotSingleResult,
  GroupedBookingSlotResult
} from '~/@types/graphql-police'
import { documents } from './documents'
import { requestPolice } from './helpers/requestPolice'

export function getOpenBookingSlots() {
  return requestPolice<GroupedBookingSlotResult>(
    documents.queries.fetchOpenBookingSlotsDocument
  )
}

export function getBookingSlotById(slotId: BookingSlot['id']) {
  return requestPolice<BookingSlotSingleResult>(
    documents.queries.fetchBookingSlotByIdDocument,
    {
      bookingSlotId: slotId
    }
  )
}
