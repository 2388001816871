import { formatToParisTime } from '~/helpers/date'
import type { Locale } from '~/i18n/config'

export const formatCheckupSlotTime = (
  slotStartDate: Date,
  slotEndDate: Date,
  options?: Intl.DateTimeFormatOptions & {
    locale?: Locale
  }
) => {
  const startTime = formatToParisTime(slotStartDate, {
    ...options
  })
  const endTime = formatToParisTime(slotEndDate, {
    ...options
  })

  return `${startTime} - ${endTime}`
}
