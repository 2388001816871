import { i18nextConfig, type Locale, type ValuesByLocale } from '~/i18n/config'

type DateFormatOptionsWithLocale = Intl.DateTimeFormatOptions & {
  locale?: Locale
}

export const PARIS_TIME_ZONE = 'Europe/Paris'

export const DATE_FORMAT_OPTIONS_BY_LOCALE: ValuesByLocale<Intl.DateTimeFormatOptions> =
  {
    fr: {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      timeZone: PARIS_TIME_ZONE
    },
    en: {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      timeZone: PARIS_TIME_ZONE
    }
  }

export function formatToParisDate(
  date: Date,
  options?: DateFormatOptionsWithLocale
) {
  const locale = options?.locale ?? i18nextConfig.fallbackLng
  const defaultOptions = DATE_FORMAT_OPTIONS_BY_LOCALE[locale]

  return date.toLocaleDateString(locale, {
    ...defaultOptions,
    ...options
  })
}

export const TIME_OPTIONS_BY_LOCALE: ValuesByLocale<Intl.DateTimeFormatOptions> =
  {
    fr: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
      timeZone: PARIS_TIME_ZONE
    },
    en: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: PARIS_TIME_ZONE
    }
  }

export const formatToParisTime = (
  date: Date,
  options?: DateFormatOptionsWithLocale
) => {
  const locale = options?.locale ?? i18nextConfig.fallbackLng
  const defaultOptions = TIME_OPTIONS_BY_LOCALE[locale]

  const timeFormatter = new Intl.DateTimeFormat(locale, {
    ...defaultOptions,
    ...options
  })

  return timeFormatter.format(date)
}
